<template>
  <div>
    <tw-header :title="$t('BreadCrumbs.Final Documents List')"></tw-header>
    <div class="tw_container">
      <tw-breadcrumbs :pages="breadcrumbs" style="margin-bottom: 40px" />
      <tw-entity-buttons />

      <el-form class="search_unit compact" ref="searchUnit" :model="searchForm" label-position="top">
        <div class="row">
          <el-form-item :label="$t('Label.Payment Terms')" prop="paymentTerms">
            <el-select v-model="searchForm.paymentTerms1" placeholder="Select">
              <el-option
                v-for="paymentTerms1 in PAYMENT_TERMS_VARIABLES"
                :key="paymentTerms1.code"
                :label="paymentTerms1.label"
                :value="paymentTerms1.code">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('Label.Trading ID / Flow ID')" prop="tradingFlowId">
            <el-input type="text" v-model.trim="searchForm.tradingFlowId" placeholder="Enter keyword" class="trading-flow"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Label.Trading Name')" prop="tradingName">
            <el-input type="text" v-model="searchForm.tradingName" placeholder="Enter keyword"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Label.Flow Name')" prop="flowName">
            <el-input type="text" v-model="searchForm.flowName" placeholder="Enter keyword"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Label.Process')" prop="processTypes">
              <el-select v-model="searchForm.processTypes" :class="{ multiple_selected: multipleSelect(searchForm.processTypes) }" placeholder="Select" multiple collapse-tags clearable>
                <el-option
                  v-for="process in processes"
                  :key="process.name"
                  :label="process.name"
                  :value="process.processType"
                >
                  <span>{{ process.name }}</span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('Label.Process User Memo')" prop="processUserMemo">
              <el-input type="text" v-model="searchForm.processUserMemo" placeholder="Enter keyword"></el-input>
            </el-form-item>
          <tw-button type="secondary" size="small" class="toggle_button" :class="{ open: openForm }" @click="openToggle">
            <img src="@/assets/images/icons/view_all.svg">
          </tw-button>
        </div>

        <transition
          name="content"
          @enter="enter"
          @after-enter="afterEnter"
          @leave="leave"
          @after-leave="afterLeave"
        >
          <div v-show="openForm">
            <div class="row">
              <el-form-item :label="$t('Label.Contract No')" prop="contractNo">
                <el-input type="text" v-model="searchForm.contractNo" placeholder="Enter keyword"></el-input>
              </el-form-item>
              <el-form-item :label="$t('Label.Invoice No')" prop="invoiceNo">
                <el-input type="text" v-model="searchForm.commercialInvoiceNo" placeholder="Enter keyword"></el-input>
              </el-form-item>
              <el-form-item :label="$t('Label.Section(From)')" prop="fromSectionIds">
                <TwSelectSection v-model="searchForm.fromSectionIds" :sections="fromSections" />
              </el-form-item>
              <el-form-item :label="$t('Label.Section(To)')" prop="toSectionIds">
                <TwSelectSection v-model="searchForm.toSectionIds" :sections="toSections" />
              </el-form-item>
              <!-- <template v-if="searchForm.paymentTerms1 === 2">
                <el-form-item :label="$t('Label.Send Date')" prop="sendDate">
                  <el-date-picker
                    v-model="searchForm.sendDate"
                    type="daterange"
                    range-separator="to"
                    format="yyyy-MM-dd"
                    :default-value="defaultDate"
                    @change.native="onDateRangeChange"
                    start-placeholder="Start date"
                    end-placeholder="End date">
                  </el-date-picker>
                </el-form-item>
              </template> -->
              <template v-if="searchForm.paymentTerms1 === PAYMENT_TERMS.LC || searchForm.paymentTerms1 === PAYMENT_TERMS.DPDA">
                <el-form-item :label="$t('Label.A/N No')" prop="anNo">
                  <el-input type="text" v-model="searchForm.anNo" placeholder="Enter keyword"></el-input>
                </el-form-item>
                <el-form-item :label="$t('Label.B/E No')" prop="beNo">
                  <el-input type="text" v-model="searchForm.beNo" placeholder="Enter keyword"></el-input>
                </el-form-item>
                <el-form-item :label="$t('Label.NEGO Date')" prop="negoDate">
                  <el-date-picker
                    v-model="searchForm.negoDate"
                    type="daterange"
                    range-separator="to"
                    format="yyyy-MM-dd"
                    :default-value="defaultDate"
                    @change.native="onDateRangeChange"
                    start-placeholder="Start date"
                    end-placeholder="End date">
                  </el-date-picker>
                </el-form-item>
              </template>
            </div>
            <div class="row">
              <el-form-item :label="$t('Label.B/L Date')" prop="blDate">
                <el-date-picker
                  v-model="searchForm.blDate"
                  type="daterange"
                  range-separator="to"
                  format="yyyy-MM-dd"
                  :default-value="defaultDate"
                  @change.native="onDateRangeChange"
                  start-placeholder="Start date"
                  end-placeholder="End date">
                </el-date-picker>
              </el-form-item>
              <el-form-item :label="$t('Label.Main Goods')" prop="mainGoods">
                <el-input type="text" v-model="searchForm.contractGroupOfGoods" placeholder="Enter keyword"></el-input>
              </el-form-item>
              <!-- <template v-if="searchForm.paymentTerms1 === PAYMENT_TERMS.LC || searchForm.paymentTerms1 === PAYMENT_TERMS.DPDA">
                <el-form-item :label="$t('Label.Bank')" prop="lcBankName">
                  <el-input type="text" v-model="searchForm.lcBankName" placeholder="Enter keyword"></el-input>
                </el-form-item>
              </template> -->
              <el-form-item :label="$t('Label.Status')" prop="processProgressStatus">
                <el-select v-model="searchForm.processProgressStatus" :class="{multiple_selected: multipleSelect(searchForm.processProgressStatus)}" placeholder="Select" multiple collapse-tags clearable>
                  <el-option
                    v-for="status in PROCESS_PROGRESS_STATUS_VARIABLES"
                    :key="status.code"
                    :label="status.label"
                    :value="status.code">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('Label.CO Application No')" prop="applicationNo" style="margin-right: 40px">
                <el-input type="text" v-model="searchForm.applicationNo" placeholder="Enter keyword"></el-input>
              </el-form-item>
            </div>
            <div class="row">
              <el-form-item class="max-content" :label="$t('Label.Create Datetime')" prop="createDatetime">
                <el-date-picker
                  v-model="searchForm.processCreateDatetime"
                  type="datetimerange"
                  range-separator="to"
                  format="yyyy-MM-dd HH:mm"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :default-time="['00:00:00', '23:59:59']"
                  start-placeholder="Start datetime"
                  end-placeholder="End datetime">
                </el-date-picker>
              </el-form-item>
              <el-form-item :label="$t('Label.Update Datetime')" prop="updateDatetime">
                <el-date-picker
                  v-model="searchForm.processUpdateDatetime"
                  type="datetimerange"
                  range-separator="to"
                  format="yyyy-MM-dd HH:mm"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :default-time="['00:00:00', '23:59:59']"
                  start-placeholder="Start datetime"
                  end-placeholder="End datetime">
                </el-date-picker>
              </el-form-item>
            </div>
          </div>
        </transition>

        <div class="row drop space_between">
          <div>
            <tw-button
              type="secondary"
              size="medium"
              icon="search"
              @click="search"
            >
              Search
            </tw-button>
            <tw-button
              type="default"
              size="medium"
              @click="clear"
              style="margin: 8px 16px 16px;"
            >
              Clear
            </tw-button>
          </div>

          <el-dropdown trigger="click" placement="bottom" @command="onDropDownClick" :tabindex="-1">
            <tw-button class="menu_button" type="secondary" size="small" icon="menu">Menu</tw-button>
            <el-dropdown-menu class="nowrap">
              <el-dropdown-item command="tsv">{{$t('Label.TSV Download')}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-form>

      <tw-table-system v-if="paymentTerms1 === PAYMENT_TERMS.TTR" :schemas="schemasTtr" :items="items" :pageSize="limit" :totalCount="totalCount" :offset="offset" :selection="selection" :serverPagination="true" @row-click="rowClick" @row-dbclick="rowDbClick" @paging="onPaging" />

      <tw-table-system v-else :schemas="schemasLc" :items="items" :pageSize="limit" :totalCount="totalCount" :offset="offset" :selection="selection" :serverPagination="true" @row-click="rowClick" @row-dbclick="rowDbClick" @paging="onPaging" />
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { $api } from '@/store/ApiClient';
import { FINAL_DOCS_SEARCH_SORT_TARGET, DEFAULT_PAGENATION, ENTITY_TYPE, PAYMENT_TERMS, PAYMENT_TERMS_VARIABLES, INITIAL_VIEW_FLG } from 'lib-tw-common';
import mixinEntityList from '@/utils/mixinEntityList.js';
import mixinEntity from '@/utils/mixinEntity.js';
import { collapseTransition } from '@/utils/nextFrame';
import { clearSearchForm, formatUtcDate } from '@/utils/searchUtil.js';
import schemasTtr from '@/dictionaries/listFinalDocsTtrSchema.json';
import schemasLc from '@/dictionaries/listFinalDocsLcSchema.json';

const ENTITY_NAME = 'FINAL_DOCS'

export default {
  name: 'ListFinalDocs',
  mixins: [mixinEntityList, collapseTransition, mixinEntity],
  data() {
    return {
      entityCode: ENTITY_TYPE.FINAL_DOCS,
      paymentTerms1: null,
      schemasTtr: schemasTtr,
      schemasLc: schemasLc,
      schemas: null,
      items: null,
      itemsLc: null,
      searchForm: {
        paymentTerms1: PAYMENT_TERMS.TTR,
        tradingFlowId: '',
        tradingName: '',
        flowName: '',
        contractNo: '',
        commercialInvoiceNo: '',
        fromSectionIds: [],
        toSectionIds: [],
        anNo: '',
        beNo: '',
        sendDate: '',
        negoDate: '',
        blDate: '',
        contractGroupOfGoods: '',
        // lcBankName: '',
        processProgressStatus: [],
        processTypes: [],
        processUserMemo: "",
        applicationNo: '',
        processUpdateDatetime: [],
        processCreateDatetime: [],
      },
      limit: DEFAULT_PAGENATION.LIMIT,
      offset: 0,
      sort: null,
      count: 0,
      totalCount: 0,
      pageCount: 0,
      PAYMENT_TERMS: PAYMENT_TERMS,
      PAYMENT_TERMS_VARIABLES: PAYMENT_TERMS_VARIABLES,
      lastQuery: {},
    };
  },
  computed: {
    breadcrumbs() {
      return [
        {label: this.$t('BreadCrumbs.Dashboard'), to: '/'},
        {label: this.$t('BreadCrumbs.Final Documents List')},
      ];
    },
  },
  created() {
    const queries = this.$store.getters.getQueries(ENTITY_NAME);
    if (queries) {
      this.searchForm = queries;
    }
    this.paymentTerms1 = this.searchForm.paymentTerms1;
    this.onModeChange();
    this.fetch();
    this.processes = this.getProcessesByEntity(ENTITY_NAME);
  },
  methods: {
    onModeChange() {
      this.paymentTerms1 = this.searchForm.paymentTerms1;
      if (this.paymentTerms1 === PAYMENT_TERMS.TTR) {
        this.schemas = schemasTtr;
      } else {
        this.schemas = schemasLc;
      }
    },
    // POSTパラメータを生成します
    transformQueries() {
      const f = this.searchForm;
      const queries = {
        fromSectionIds: f.fromSectionIds,
        toSectionIds: f.toSectionIds,
        blStartDate: this.getYMDStartDate(f.blDate),
        blEndDate: this.getYMDEndDate(f.blDate),
        tradingId: this.getTradingFlowId(f.tradingFlowId)[0],
        tradingFlowId: this.getTradingFlowId(f.tradingFlowId)[1],
        tradingName: f.tradingName,
        flowName: f.flowName,
        contractNo: f.contractNo,
        invoiceNo: f.commercialInvoiceNo,
        mainGoods: f.contractGroupOfGoods,
        processProgressStatus: this.getProcessProgressStatus(f.processProgressStatus),
        processIds: f.processTypes ? _.flatten(_.map(f.processTypes, item => this.getProcessIdsByProcessType(item))) : [],
        processUserMemo: f.processUserMemo,
        applicationNo: f.applicationNo,
        processUpdateDateTimeFrom: f.processUpdateDatetime ? formatUtcDate(f.processUpdateDatetime[0]) : null,
        processUpdateDateTimeTo: f.processUpdateDatetime?.[1] ? formatUtcDate(f.processUpdateDatetime[1]).replace('00Z', '59Z') : null,
        processCreateDateTimeFrom: f.processCreateDatetime ? formatUtcDate(f.processCreateDatetime[0]) : null,
        processCreateDateTimeTo: f.processCreateDatetime?.[1] ? formatUtcDate(f.processCreateDatetime[1]).replace('00Z', '59Z') : null,
      }

      const ret = {
        ...queries,
        initialFlag: this.initialFlag(queries),
        paymentTerms: f.paymentTerms1,
        limit: this.limit,
        offset: this.offset,
        sort: this.sort,
      };

      if (this.searchForm.paymentTerms1 === PAYMENT_TERMS.LC || this.searchForm.paymentTerms1 === PAYMENT_TERMS.DPDA) {
        return {
          ...ret,
          initialFlag: INITIAL_VIEW_FLG.OFF,
          paymentTerms: f.paymentTerms1,
          anNo: f.anNo,
          beNo: f.beNo,
          negotiationStartDate: this.getYMDStartDate(f.negoDate),
          negotiationEndDate: this.getYMDEndDate(f.negoDate),
          // bank: f.lcBankName,
        }
      }
      return ret;
    },
    fetch(queries) {
      this.cancelRequestSources.forEach(tag => {
        $api.cancelRequests(tag);
      });
      // bff_fd_1 決済一覧検索BFF
      const params = {
        lslConfig: {
          serviceCode: 'tw-transaction-bff-api',
          apiCode: 'get_/v1/final-docs/search',
          query: queries || this.transformQueries()
        },
        tag: this.pushCancelTag(),
      };

      this.lastQuery = _.cloneDeep(params.lslConfig.query);

      $api.request(params)
      .then(res => {
        this.items = res.finals;
        this.totalCount = res.totalCount;
      })
      .catch(err => {
        if (err.isCanceled) {
          return;
        }
        this.items = [];
        this.$store.dispatch('SHOW_ALERT', err.message);
      });
    },
    search() {
      this.offset = 0;
      this.onModeChange();
      this.$store.commit('SET_QUERIES', { key: ENTITY_NAME, queries: _.cloneDeep(this.searchForm) });
      this.fetch();
    },
    clear() {
      this.searchForm = clearSearchForm(this.searchForm);
      this.$store.commit('SET_QUERIES', { key: ENTITY_NAME, queries: _.cloneDeep(this.searchForm) });
    },
    onPaging({target, sortType, currentPage}) {
      if (target && sortType) {
        const key = _.get(_.find(this.schemas, {key: target}), 'sortTarget');
        this.sort = {
          target: [FINAL_DOCS_SEARCH_SORT_TARGET[key] || target],
          sortType: [sortType],
        };
      } else {
        this.sort = null;
      }

      this.offset = currentPage * this.limit - this.limit;
      const queries = {
        ...this.lastQuery,
        limit: this.limit,
        offset: this.offset,
        sort: this.sort,
      };
      this.fetch(queries);
    },
    // TSVダウンロード用に全件取得します
    fetchAll() {
      return new Promise((resolve, reject) => {
        // bff_fd_1 決済一覧検索BFF
        const params = {
          lslConfig: {
            serviceCode: 'tw-transaction-bff-api',
            apiCode: 'get_/v1/final-docs/search',
            query: { ...this.transformQueries(), offset: undefined, limit: undefined }
          }
        };

        $api.request(params)
        .then(res => {
          resolve(res.finals);
        })
        .catch(err => {
          this.$store.dispatch('SHOW_ALERT', err.message);
          reject();
        });
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.search_unit .max-content {
  width: max-content;
}
</style>
